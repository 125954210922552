import { Component, ElementRef, Input, ViewChild, EventEmitter, Output, Renderer2 } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpService } from '../../http/http.service';
import { CaseInfoModalService } from '../case-info-modal.service';
import { UtilsService } from '../../utils/utilis.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { environment } from '../../../../environments/environment'
import { image } from 'html2canvas/dist/types/css/types/image';

declare module 'pdfmake/build/pdfmake';
declare module 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-case-info-modal',
  templateUrl: './case-info-modal.component.html',
  styleUrls: ['./case-info-modal.component.scss'],
})
export class CaseInfoModalComponent {
  showError(arg0: string) {
    throw new Error('Method not implemented.');
  }
  showSuccess(arg0: string) {
    throw new Error('Method not implemented.');
  }
  @Input() data: any;
  @Output() dataEmitter = new EventEmitter<string>();
  wshaList: any[] = [];
  selectedWSHA: any;
  selectedItem: any;
  @Input() isChangeStatus: boolean = false;
  @Output() isOpenDataChange = new EventEmitter<boolean>();

  imageSize = {
    width: '44px',
    height: '144px',
    space: 1,
  };
  images: any = [];

  resolvedCaseMedia: any = [];
  inprogressCaseMedia: any = [];
  rejectCaseMedia: any = [];
  BeforeImageArray!: any[];
  imageCanva!: any;
  companyName!: String;
  companyLogo!: String;

  approval_id!: String;
  approval_email!: String;
  approval_role!: string;
  approval_name!: String;

  reassignee_id!: String;
  reassignee_email!: String;
  reassignee_role!: string;
  reassignee_name!: String;

  rejector_id!: String;
  rejector_email!: String;
  rejector_role!: string;
  rejector_name!: String;

  confirmCallback: () => void = () => { };
  cancelCallback: () => void = () => { };

  constructor(
    public bsModalRef: BsModalRef,
    private http: HttpService,
    private httpClient: HttpClient,
    private httpClientModule: HttpClientModule,
    private caseDetailsModalService: CaseInfoModalService,
    public utils: UtilsService,
    private renderer: Renderer2,
    // private latitude: number | null = null,
    // private longitude: number | null = null,

    // private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.selectedItem = this.data;
    this.images = this.utils.buildImageArray(this.selectedItem[0]?.case_media);
    const resolvedStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'approval pending');
    const inprogressStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'inprogress');
    const rejectStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'reject');
    const approveStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'resolved');
    const reassignStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'reassign');

    this.resolvedCaseMedia = this.utils.buildImageArray(resolvedStateObject[0]?.t_resolved_case_media);
    this.inprogressCaseMedia = this.utils.buildImageArray(inprogressStateObject[0]?.t_resolved_case_media);
    this.rejectCaseMedia = this.utils.buildImageArray(rejectStateObject[0]?.t_resolved_case_media);
    this.rejectCaseMedia = this.utils.buildImageArray(approveStateObject[0]?.t_resolved_case_media);
    this.rejectCaseMedia = this.utils.buildImageArray(reassignStateObject[0]?.t_resolved_case_media);


    this.approval_id = (approveStateObject[0]?.action_taker)
    this.approval_name = (approveStateObject[0]?.action_taker_name)
    this.approval_email = (approveStateObject[0]?.action_taker_email)
    this.approval_role = (approveStateObject[0]?.action_taker_role)

    this.reassignee_id = (reassignStateObject[0]?.action_taker)
    this.reassignee_name = (reassignStateObject[0]?.action_taker_name)
    this.reassignee_email = (reassignStateObject[0]?.action_taker_email)
    this.reassignee_role = (reassignStateObject[0]?.action_taker_role)

    this.rejector_id = (rejectStateObject[0]?.action_taker)
    this.rejector_name = (rejectStateObject[0]?.action_taker_name)
    this.rejector_email = (rejectStateObject[0]?.action_taker_email)
    this.rejector_role = (rejectStateObject[0]?.action_taker_role)


    const originUrl = this.utils.getOrigin();
    const companyId = this.selectedItem[0]?.assigned_by_co_id;
    if (companyId) {
      this.getCompaney(companyId);
    };
    this.updateGoogleMapsLink();
  }

  onCancelClick(): void {
    this.bsModalRef.hide();
  }

  downloadImage(urls: { image: string }[]) {

    console.log("this is the url of the image : ", urls)
    // Check if the URL array is valid and non-empty
    if (!urls || urls.length === 0) {
      console.log('No URLs provided');
      return;
    }

    urls.forEach((urlObject, index) => {
      // Ensure the URL is a valid string
      const url_image = urlObject?.image;

      if (typeof url_image !== 'string') {
        console.log('Invalid URL provided at index', index, ':', url_image);
        return;
      }

      // HTTP request to download the image
      this.httpClient.get(url_image, { responseType: 'blob' }).subscribe(blob => {
        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        // Set the filename for each image with an index
        link.download = `case_media_${index + 1}.jpg`;

        // Append the link to the body, click it, then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, error => {
        console.error('Error downloading the image at index', index, ':', error);
      });
    });
  }

  DownloadDetails(caseid: any) {
    let data = document.getElementById('content')!;
    html2canvas(data, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/jpeg');
      let pdf = new jsPDF('p', 'mm', 'a4');

      let position = 5;

      pdf.addImage(contentDataURL, 'JPEG', 5, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'JPEG', 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`case_${caseid}.pdf`);
    });
  }
  // downloadImage(event: any) {
  //   const imageIndex = event;
  //   const image = this.images[imageIndex];
  //   const link = document.createElement('a');
  //   link.href = image.image;
  //   link.download = image.title;
  //   link.click();
  // }

  statusReasonInput: string = '';
  isRejectClicked: boolean = false;

  onRejectClick(): void {
    this.isRejectClicked = true;
  }

  closeRejectModal() {
    this.isRejectClicked = false;
  }

  onSubmitReject(): void {
    if (this.statusReasonInput.trim()) {
      this.updateStatus('reject', this.statusReasonInput, 'Case has been rejected');
    } else {
      this.utils.showError("Please provide a rejection reason.");
    }
  }

  isReassingClicked: boolean = false;

  onReassignClick(): void {
    this.isReassingClicked = true;
  }

  closeReassingModal() {
    this.isReassingClicked = false;
  }

  onSubmitReassign(): void {
    if (this.statusReasonInput.trim()) {
      this.updateStatus('reassign', this.statusReasonInput, 'Case has been reopened');
    } else {
      this.utils.showError("Please provide a reassign reason.");
    }
  }

  onApproveClick(): void {
    this.updateStatus('resolved', 'Case has been resolved', 'Case has been approved');
  }

  private updateStatus(status: string, statusReason: string, statusDescription: string): void {
    if (!this.selectedItem || !this.selectedItem[0]?.case_id) {
      this.utils.showError('Case ID is missing');
      return;
    }
    const loggedInUserId = this.utils.getLoggedInUserDetails()['user_id']; // Replace 'user_id' with the actual key
    console.log("loggedInUserId", loggedInUserId)
    const payload = {
      case_id: this.selectedItem[0].case_id,
      assignee_id: loggedInUserId, // assignee_id: this.selectedItem[0].assigned_to,
      status: status,
      status_reason: statusReason,
      status_description: statusDescription,
      resolved_case_media: [],
    };

    console.log("Payload being sent", payload);

    this.http.post('case-actions', payload).subscribe(
      (response) => {
        this.utils.showSuccess(`Case ${status} successfully`);
        this.isChangeStatus = !this.isChangeStatus;
        this.isOpenDataChange.emit(this.isChangeStatus);
        this.doNotify(this.selectedItem[0].case_id, this.selectedItem[0].assigned_to, status);
        setTimeout(() => {
          this.bsModalRef.hide();
        }, 2000); // 1 seconds delay
      },
      (error) => {
        console.error('Error updating status:', error);
        this.utils.showError(`Error ${status}ing case`);
      }
    );
  }

// send notification upon case status change
private doNotify(caseId: any, assigneeId: any, action: string) {
  let notificationObject = {
    user_id: assigneeId, 
    case_id: caseId,
    action: action, 
    data: {}, 
    message_title: null, 
    message_body: null,
  };

  // API call /notify
  this.http.post('notify', notificationObject).subscribe(
    (res) => {
      console.log("Notification has been sent successfully!");
    },
    (err) => {
      console.log('Error sending notification!');
    }
  );
}

// Geting Main Contractor Details
  private getCompaney(company_id: string): void {
    if (!this.selectedItem || !this.selectedItem[0]?.assigned_by_co_id) {
      this.utils.showError('Company ID is missing');
      return;
    }

    const payload = {
      company_id: this.selectedItem[0].assigned_by_co_id
    };

    this.http.post('companyDetails', payload).subscribe(
      (response: any) => {
        if (response.status === 200) {
          // Show the company name and logo or any other details
          this.companyName = response.data.company_name;
          this.companyLogo = response.data.company_logo;

          // Display or process the company data
          console.log('Company Name:', this.companyName);
          console.log('Company Logo:', this.companyLogo);

          // Use this data for rendering purposes in the frontend (e.g., in a template)
        } else {
          // Handle the case where no company details are found
          this.utils.showError(response.message);
        }
      },
      (error) => {
        // Handle HTTP errors
        console.error('Error fetching company details:', error);
        this.utils.showError('Failed to fetch company details');
      }
    );
  }

  convertImageToBase64logo = (imageUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imagetoBase64 = reader.result as string;
            console.log("base64Output:", imagetoBase64); // Log the Base64 string to the console
            resolve(imagetoBase64); // Resolve the promise with the base64 string
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error converting image:', error);
          reject(error); // Reject the promise on error
        });
    });
  }


  getStatusStyle(status: string): any {
    switch (status.toLowerCase()) {
      case 'inprogress':
        return { color: '#FFA500' };
      case 'open':
        return { color: '#00BFFF' };
      case 'resolved':
        return { color: '#02a618' };
      case 'reject':
        return { color: '#FF0000' };
      case 'approval pending':
        return { color: '#f5ca1d' };
      case 'reassign':
        return { color: '#8A2BE2' };
      default:
        return {}; // default style if status is unknown
    }
  }
  // to get the url of the map
  get latitude(): number {
    return this.selectedItem?.[0]?.case_lat || 0; // Fallback to 0 or a default value if undefined
  }

  get longitude(): number {
    return this.selectedItem?.[0]?.case_lon || 0; // Fallback to 0 or a default value if undefined
  }
  get mapUrl() {
    const lat = this.latitude;
    const lon = this.longitude;
    const bboxOffset = 0.005; // Adjust this value to change the bounding box size
    return `https://www.openstreetmap.org/export/embed.html?bbox=${lon - bboxOffset},${lat - bboxOffset},${lon + bboxOffset},${lat + bboxOffset}&layer=mapnik&marker=${lat},${lon}`;
  }
  getGoogleMapsLink(latitude: number, longitude: number): string {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
  }
  updateGoogleMapsLink(): void {
    if (this.selectedItem && this.selectedItem.length > 0) {
      this.googleMapsLink = this.getGoogleMapsLink(this.latitude, this.longitude);
    }
  }
  // Example usage
  googleMapsLink = this.getGoogleMapsLink(this.latitude, this.longitude);

  async generatePDF(selectedItem: any, company_logo: any, company_name: any) {
    const now = new Date();
    const generatedAt = now.toLocaleString('en-SG', { timeZone: 'Asia/Singapore' });
    let imagetoBase64;
    let maplink;
    console.log("company log ", company_logo)
    console.log("name of the company", company_name)
    // let base64ofLogo = await this.CaseImage(company_logo);
    const originUrl = this.utils.getOrigin();
    let base64ofLogo = await this.convertImageToBase64logo(`${originUrl}${company_logo}`);
    console.log("this is the base 64 of the logo  : ", base64ofLogo);

    // Convert image to Base64
    const convertToBase64 = () => {
      return new Promise<void>((resolve, reject) => {
        // const imageUrl = 'https://static-maps.yandex.ru/1.x/?ll=77.1716954,28.6273928&size=600,400&z=14&l=map';
        const imageUrl = `https://static-maps.yandex.ru/1.x/?ll=${this.longitude},${this.latitude}&size=600,400&z=12&l=map`;
        // const imageUrl = `https://staticmap.openstreetmap.de/staticmap.php?center=37.7749,-122.4194&zoom=15&size=600x400&maptype=mapnik`;
        console.log("this is the image url  : " + imageUrl);
        maplink = imageUrl
        fetch(imageUrl)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              imagetoBase64 = reader.result as string;
              console.log("base64Output : " + imagetoBase64); // Log the Base64 string to the console
              resolve(); // Resolve the promise when conversion is done
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => {
            console.error('Error converting image:', error);
            reject(error); // Reject the promise on error
          });
      });
    };

    // Await the conversion to finish before creating the PDF
    await convertToBase64();

    const caseMedia = selectedItem[0]?.case_media || [];
    const resolvedStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'approval pending');
    const resolvedCaseMedia = resolvedStateObject[0]?.t_resolved_case_media || [];
    const approveStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'resolved');


    const rejectStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'reject');
    const reassignStateObject = this.selectedItem.filter((obj: any) => obj.t_status === 'reassign');


    const approval_id = (approveStateObject[0]?.action_taker)
    const approval_name = (approveStateObject[0]?.action_taker_name)
    const approval_email = (approveStateObject[0]?.action_taker_email)
    const approval_role = (approveStateObject[0]?.action_taker_role)

    const reassignee_id = (reassignStateObject[0]?.action_taker)
    const reassignee_name = (reassignStateObject[0]?.action_taker_name)
    const reassignee_email = (reassignStateObject[0]?.action_taker_email)
    const reassignee_role = (reassignStateObject[0]?.action_taker_role)

    const rejector_id = (rejectStateObject[0]?.action_taker)
    const rejector_name = (rejectStateObject[0]?.action_taker_name)
    const rejector_email = (rejectStateObject[0]?.action_taker_email)
    const rejector_role = (rejectStateObject[0]?.action_taker_role)

    let status = selectedItem[0].status;

    // Define the document definition
    const docDefinition: any = {
      pageSize: 'A4', // Set page size to A4
      pageOrientation: 'landscape',
      watermark: { text: 'Snap@Champion', color: 'lightgray', opacity: 0.3, bold: true, italics: false, fontSize: 70 }, // Watermark
      content: [
        {
          columns: [
            // Logo and link on the left
            {
              stack: [
                {
                  image: base64ofLogo,
                  width: 100,
                  alignment: 'left',
                  margin: [0, 0, 0, 10],
                },
                {
                  text: company_name,
                  alignment: 'left',
                  bold: true,
                  fontSize: 11
                }

              ],
              width: '35%'  // Adjusted width to take up more space
            },
            // Headers in the center, but pushed slightly to the left
            {
              stack: [
                { text: 'Case Inspection Report', style: 'header', alignment: 'center' },
                { text: '\n' },
                {
                  text: [
                    { text: 'SITE ID : ', fontSize: 18, bold: true }, // Bold and larger font size
                    { text: selectedItem[0].project_code + ' - ' + this.utils.capitalizeName(selectedItem[0].project_name) }
                  ],
                  style: 'subheader',
                  alignment: 'center'
                },
                { text: '\n' }
              ],
              width: '35%'  // More space to the left, but slightly reduced width
            }
          ]
        },
        { text: '\n' },

        {
          columns: [
            {
              alignment: 'right',  // Right-aligned
              text: [
                { text: 'STATUS : ', color: 'black', bold: true },  // The key "STATUS" with default or black color
                { text: this.utils.capitalizeName(selectedItem[0].status), color: this.getStatusStyle(selectedItem[0].status).color }  // The value with dynamic color
              ]
            }
          ]
        },
        { text: '\n' },

        // Case General Details
        { text: 'General Information', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: 'Case ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10], },
                { text: 'Category', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Location', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Created At', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Case Deadline', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Description', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                {
                  text: selectedItem[0].case_id ? selectedItem[0].case_id : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].case_category ? selectedItem[0].case_category : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].case_location ? selectedItem[0].case_location : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].created_at ? this.utils.getSGTZoneDateTime(selectedItem[0].created_at) : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].case_deadline ? selectedItem[0].case_deadline : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].case_description ? selectedItem[0].case_description : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                }
              ],

            ],
          },

        },

        { text: '\n' },

        // Worksite Details
        {
          text: selectedItem[0].case_type === 'company'
            ? 'Worksite Details'
            : '',
          style: 'subheader',
        },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],
            dontBreakRows: true,  // Ensure rows don’t break across pages
            body: [
              [
                { text: 'Site ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Project Code', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Project Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Contact', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                {
                  text: selectedItem[0].siteid ? selectedItem[0].siteid : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].project_code ? selectedItem[0].project_code : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: this.utils.capitalizeName(selectedItem[0].project_name) ? this.utils.capitalizeName(selectedItem[0].project_name) : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].contact_number ? selectedItem[0].contact_number : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                }
              ],
            ],
          },
        },

        { text: '\n', pageBreak: 'before' },

        // Case Creator Details
        { text: 'Case Creator Details', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Creator ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Creator Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Creator Role', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Creator Email', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                {
                  text: selectedItem[0].assigned_by ? selectedItem[0].assigned_by : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].assigned_by_name ? selectedItem[0].assigned_by_name : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].assigned_by_role ? this.utils.getMappedRoleDisplayName(selectedItem[0].assigned_by_role) : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                },
                {
                  text: selectedItem[0].assigned_by_email ? selectedItem[0].assigned_by_email : 'NA',
                  margin: [10, 10, 10, 10],
                  alignment: 'center'
                }
              ],
            ],
          },
        },

        { text: '\n' },

        // Case Closer Details
        { text: 'Case Closer Details', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Closer ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Closer Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Closer Role', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Closer Email', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                { text: selectedItem[0].assigned_to ? selectedItem[0].assigned_to : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: selectedItem[0].assigned_to_name ? selectedItem[0].assigned_to_name : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: selectedItem[0].assigned_to_role ? this.utils.getMappedRoleDisplayName(selectedItem[0].assigned_to_role) : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: selectedItem[0].assigned_to_email ? selectedItem[0].assigned_to_email : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
              ],
            ],
          },
        },
        { text: '\n' },


        // statusRendering,
        // Case Approver Details
        ...(status === 'resolved'
        ? [
            { text: 'Case Approver Details', style: 'subheader' },
            {
              style: 'tableExample',
              table: {
                widths: ['*', '*', '*', '*'],
                body: [
                  [
                    { text: 'Approver ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                    { text: 'Approver Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                    { text: 'Approver Role', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                    { text: 'Approver Email', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                  ],
                  [
                    { text: approval_id || 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                    { text: approval_name || 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                    { text: approval_role ? this.utils.getMappedRoleDisplayName(approval_role) : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                    { text: approval_email || 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                  ],
                ],
              },
            },
          ]
        : []),
        // Case reassignee Details
        ...(status === 'reassign'
        ? [
        { text: 'Case Reassignee Details', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Reassignee ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Reassignee Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Reassignee Role', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Reassignee Email', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                { text: reassignee_id ? reassignee_id : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: reassignee_name ? reassignee_name : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: reassignee_role ? this.utils.getMappedRoleDisplayName(reassignee_role) : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: reassignee_email ? reassignee_email : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
              ],
            ],
          },
        },] : []),
        // Case Rejector Details
        ...(status === 'reject'
        ? [
        { text: 'Case Rejector Details', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: 'Rejector ID', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Rejector Name', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Rejector Role', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
                { text: 'Rejector Email', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },
              ],
              [
                { text: rejector_id ? rejector_id : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: rejector_name ? rejector_name : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: rejector_role ? this.utils.getMappedRoleDisplayName(rejector_role) : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
                { text: rejector_email ? rejector_email : 'NA', margin: [10, 10, 10, 10], alignment: 'center' },
              ],
            ],
          },
        },] : []),


        // Page break before Case Images Details
        { text: '\n', pageBreak: 'before' },
        { text: 'Case Images Details', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*', '*'],
            body: [
              [
                { text: 'Photo(s) - Before', style: 'tableHeader', fillColor: '#D3D3D3', margin: [5, 5, 5, 5] },
                { text: 'Photo(s) - After', style: 'tableHeader', fillColor: '#D3D3D3', margin: [5, 5, 5, 5] },
              ],
              [
                await this.CaseImage(caseMedia),
                await this.CaseImage(resolvedCaseMedia)
              ]
            ],
          },
        },
        // Page break before Case Location Map
        { text: '\n', pageBreak: 'before' },

        // Case Location map Details
        { text: 'Map Image', style: 'subheader' },
        {
          style: 'tableExample',
          table: {
            widths: ['*'],
            body: [
              [
                { text: 'Location Map [ click to view location on map ]', style: 'tableHeader', fillColor: '#D3D3D3', margin: [10, 10, 10, 10] },

              ],
              [
                {
                  image: imagetoBase64, // Embed the base64 image in the PDF
                  width: 750,
                  height: 300,
                  link: this.googleMapsLink,
                },
              ],
            ],
          },
        },
      ],
      footer: function (currentPage: number, pageCount: number) {
        return {
          columns: [
            { text: `Generated At: ${generatedAt} SGT`, alignment: 'left', style: 'footer' },  // Left-aligned
            {
              alignment: 'center',  // Center-aligned text
              text: [
                { text: 'Innovated By: ', color: 'black' },  // "Innovated By" in black
                { text: 'Champion Management Services Pte Ltd', color: 'darkblue', bold: true },// "Champion Management Services Pte Ltd" in dark blue and bold
              ],
              link: 'http://www.champmgt.com.sg',
              style: 'footer'
            },

            { text: `Page ${currentPage} of ${pageCount}`, alignment: 'right', style: 'footer' },  // Right-aligned
          ],
          margin: [40, 0]
        };
      },
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          alignment: 'center',
        },
        subheader: {
          fontSize: 18,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          alignment: 'center',
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        timestamp: {
          fontSize: 12,
          alignment: 'right',
          margin: [0, 0, 0, 10],
        },
        footer: {
          fontSize: 10,
          alignment: 'center',
          margin: [0, 10, 0, 0],
        },
      },

    };
    console.log("this is the docs defination : ", docDefinition)
    pdfMake.createPdf(docDefinition).download(`${selectedItem[0].case_id}-Detail_Report.pdf`);
  }

  async CaseImage(urls: { image: string }[]): Promise<any[]> {
    console.log("This is case image URLs:", urls);
    console.log("This is case image URLs length : ", urls.length);

    if (urls.length === 0) {
      return ['No Image Found']
    }

    // if(urls)

    // Check if the URL array is valid and contains at least one item
    if (!Array.isArray(urls) || urls.length === 0) {
      console.error('Invalid URL array provided:', urls);
      return [{ image: 'data:image/png;base64,default_image_base64_string' }]; // Return a default image if needed
    }

    // Map URLs to promises that fetch and process each image
    const ArrayOfImages = await Promise.all(
      urls.map(async (urlObj) => {
        const originUrl = this.utils.getOrigin();
        const url_image = `${originUrl}${urlObj}`; // Ensure urlObj.image is valid
        // const url_image = `${environment.apiUplode}/${urlObj}`; // Ensure urlObj.image is valid

        console.log("Image URL:", url_image);

        try {
          const response = await fetch(url_image);
          if (!response.ok) {
            throw new Error(`Image fetch failed with status: ${response.status}`);
          }
          const blob = await response.blob();
          const dataURL = await this.blobToDataURL(blob);
          return {
            image: dataURL, // Use Base64 for the image
            margin: [1, 1, 1, 1],
            fit: [300, 150],
            width: 300,
            height: 200,
            alignment: 'center'

          };
        } catch (error) {
          console.error('Error fetching or converting image:', error);
          return null; // Return null if an error occurs
        }
      })
    );

    // Filter out null results
    const filteredImages = ArrayOfImages.filter(img => img !== null);
    return filteredImages.length > 0 ? filteredImages : [{ image: 'data:image/png;base64,default_image_base64_string', margin: [10, 10, 10, 10], fit: [150, 150] }]; // Default if no images
  }

  // converting the image to the data usrl using blob

  private blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async fetchMapImage(mapUrl: string): Promise<string> {
    console.log("this mappppppp", mapUrl);
    return new Promise((resolve, reject) => {
      this.httpClient.get(mapUrl, { responseType: 'blob' }).subscribe({
        next: (blob) => {
          console.log("This is the blob: ", blob);
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string); // Resolve with base64 string
          reader.onerror = (error) => reject(error); // Handle file reading error
          reader.readAsDataURL(blob); // Convert blob to base64
        },
        error: (err) => {
          console.error("Error fetching image: ", err);
          reject(err); // Handle HTTP error
        }
      });
    });
  }

}
