// side-panel.service.ts

import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { ClinetSettingsDTO } from 'src/app/components/dto/client-settings.dto';
import { LoggedInUserModel } from 'src/app/components/dto/loggedIn-user.dto';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { HttpService } from '../http/http.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  constructor(private router: Router, private http: HttpService,
    private toastr: ToastrService  // Add toastr injection
  ) {}
  // Error and success notifications
  showError(message: string): void {
    this.toastr.error(message, 'Error', {
      timeOut: 3000,
    });
  }

  showSuccess(message: string): void {
    this.toastr.success(message, 'Success', {
      timeOut: 3000,
    });
  }

  private blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  // Role Based Access Control Navigation
  doRoleBasedNavigation(role: String) {
    let isAuthorized = true;
    switch (role) {
      case 'super-admin':
        this.router.navigate(['/dashboard/overview']);
        break;
      case 'company-main-contractor-admin':
        this.router.navigate(['/cc-dashboard/cc-overview']);
        break;
      case 'company-contractor-secondary-admin':
        this.router.navigate(['/cc-dashboard/cc-overview']);
        break;
      default:
        // this.router.navigate(['/login']);
        isAuthorized = false;
        break;
    }
    return isAuthorized;
  }

  // Logout
  doLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  // extract loggedin Users details
  getLoggedInUserDetails() {
    let tempObj: any = localStorage.getItem('logged-user');
    const user = Object.assign(new LoggedInUserModel(), JSON.parse(tempObj));
    return user;
  }

  getClientSettingsFromDB(userId: any) {
    // save client settings
    this.http.post('getClientSettings', { user_id: userId }).subscribe(
      (res) => {
        if (res['data'].length > 0) {
          localStorage.setItem(
            'client-settings',
            JSON.stringify(res['data'][0])
          );
          this.setClientSettings(res['data'][0]);
          return res['data'][0];
        }
      },
      (err) => {
        console.log('Error getting client settings');
      }
    );
  }

  setClientSettings(settingsData: any) {
    const clientSettings = Object.assign(new ClinetSettingsDTO(), settingsData);
    document.documentElement.style.setProperty(
      '--ss-menu-panel-background-color',
      clientSettings.menu_panel_bg
    );
    document.documentElement.style.setProperty(
      '--ss-top-header-background-color',
      clientSettings.top_bar_bg
    );
    document.documentElement.style.setProperty(
      '--ss-sidebar-background-color',
      clientSettings.right_panel_bg
    );
  }

  // setDefaultClientSettings

  // export to excel
  exportToExcel(data: any[], sheetName: string, fileName: string): void {
    // Create a worksheet
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Create a workbook
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  // form multipartForm data handler
  formMultipartFormDataHandler(formName: any) {
    const formData = new FormData();
    // Loop through form controls
    Object.keys(formName.controls).forEach((key) => {
      const control = formName.get(key);
      // Check if the control is a file input
      if (control instanceof FormControl && control.value instanceof FileList) {
        const files = control.value;
        for (let i = 0; i < files.length; i++) {
          formData.append(key, files[i]);
        }
      } else {
        // For non-file inputs
        formData.append(key, control.value);
      }
    });

    return formData;
  }

  // Role mapper
  getMappedRoleDisplayName(roleAlias: string) {
    let role;
    switch (roleAlias) {
      case 'company-main-contractor-admin':
        role = 'Main Contractor Admin';
        break;
      case 'company-contractor-secondary-admin':
        role = 'WSH Inspector';
        break;
      case 'company-contractor-director':
        role = 'Director';
        break;
      case 'sub-contractor':
        role = 'Sub Contractor';
        break;
      case 'wsha-director':
        role = 'Director';
        break;
      case 'wsha-senior-hse-executive':
        role = 'Senior HSE Executive';
        break;
      case 'wsha-officer':
        role = 'Workplace Safety & Health Officer';
        break;
      case 'wsha-co-ordinator':
        role = 'Workplace Safety & Health Coordinator';
        break;
      case 'wsha-ambassador':
        role = 'WSHA Ambassador';
        break;
      case 'super-admin':
        role = 'Super Admin';
        break;
      default:
        role = 'Public';
        break;
    }
    return role;
  }

  capitalizeName(name: string) {
    // Split the name into words
    let words = name.split(' ');
    // Capitalize the first letter of each word
    let capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    // Join the capitalized words back into a string
    let capitalizedName = capitalizedWords.join(' ');

    return capitalizedName;
  }

  // Date conversions

  getSGTZoneDateTime(utcDateString: any) {
    const utcDate = new Date(utcDateString);
    // Convert to Singapore time
    const singaporeTime = new Date(
      utcDate.toLocaleString('en-US', { timeZone: 'Asia/Singapore' })
    );

    // Format the date string in 'DD/MM/YYYY' format
    const formattedDate = singaporeTime.toLocaleDateString('en-SG', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formattedDate || '';
  }

  // Restrict birthdate years limitation
  getBirthDateYearRange() {
    let minDate = new Date();
    let maxDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 75); // 75 years old
    maxDate.setFullYear(maxDate.getFullYear() - 22); // minimum person should have completed 22 years old
    return { min: minDate, max: maxDate };
  }

  getAppOrigin() {
    let originUrl = environment.apiUrl;
    return originUrl.replace('/v1/api', '');
  }

  getOrigin() {
    let originUrl = environment.apiUrl;
    let appEnv = environment.environment;
    //console.log("App Environment Media Folder >>>>> ", appEnv);
    // Remove '/v1/api' from the URL
    let modifiedURL = originUrl.replace('/v1/api', '');
    //console.log(`${modifiedURL}/${appEnv}_uploads/`);
    return `${modifiedURL}/uploads/`;
  }

  buildImageArray(images: Array<string>) {
    if (images == undefined || images == null) {
      return null;
    }
    let originUrl = this.getOrigin();
    let imagesArray: any = [];
    images.forEach((img: string) => {
      let sliderImageObj = {
        image: `${originUrl}${img}`,
        thumbImage: `${originUrl}${img}`,
      };
      imagesArray.push(sliderImageObj);
    });

    return imagesArray;
  }

  // discontinued
  parseImage(profilePictureData: any) {
    const buffer = new Uint8Array(profilePictureData);
    const binary = buffer.reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    );
    const img = 'data:image/*;base64,' + btoa(binary);
    return img;
  }

  transformTrendGraphDataStructure(input: any) {
    const colorMap: any = {
      Open: '#00BFFF',
      Inprogress: '#FFA500',
      Resolved: '#02a618',
      Rejected: '#FF0000',
      Unassigned: '#bcbcbc',
      'Approval pending': '#f5ca1d', // Key with space in quotes
      Reassign: '#8A2BE2',
    };

    const output = {
      labels: input.trend_graph_data.labels.map((label: any) => {
        const date = new Date(label);
        return `${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
          date
        )}-${date.getFullYear()}`;
      }),
      datasets: input.trend_graph_data.datasets.map((dataset: any) => ({
        label: dataset.label,
        data: dataset.data,
        backgroundColor: colorMap[dataset.label],
        borderColor: colorMap[dataset.label],
        fill: false,
        lineTension: 0,
        radius: 3,
        borderWidth: 2,
      })),
    };

    return output;
  }

  // donut graph

  transformDonutGraphDataStructure(
    inputData: DonutGraphData
  ): DonutGraphOutput {
    if (
      inputData.donut_graph_data == null ||
      inputData.donut_graph_data == undefined
    ) {
      return {
        labels: ['Data Unavailable'],
        datasets: [
          {
            data: [100],
            backgroundColor: ['#F5F6FA'],
          },
        ],
      };
    }
    // Color mapping for each label
    const colorMap: Record<string, string> = {
      Open: '#00BFFF',
      Inprogress: '#FFA500',
      Resolved: '#02a618',
      Reject: '#FF0000',
      Unassigned: '#bcbcbc',
      'Approval pending': '#f5ca1d', // Key with space in quotes
      Reassign: '#8A2BE2',
    };

    const labels: string[] = [];
    const dataset: DonutDatasetOutput = {
      data: [],
      backgroundColor: [],
    };

    // Iterate through provided dataset and generate output
    inputData.donut_graph_data.forEach((donutDataset: DonutDataset) => {
      // console.log("Label for donuts >>> ", donutDataset.label);
      const label =
        donutDataset.label.charAt(0).toUpperCase() +
        donutDataset.label.slice(1); // Capitalize the first letter // Convert label to lowercase for case-insensitive matching
      const backgroundColor = colorMap[label] || '#000000';

      labels.push(label);
      dataset.data.push(donutDataset.data);
      dataset.backgroundColor.push(backgroundColor);
    });

    return { labels, datasets: [dataset] };
  }
}

interface Dataset {
  data: number[];
  label: string;
}

interface ColorMap {
  backgroundColor: string;
  borderColor: string;
}

interface DatasetOutput {
  label: string;
  data: number[];
  backgroundColor: string;
  borderColor: string;
  fill: boolean;
  lineTension: number;
  radius: number;
  borderWidth: number;
}

interface TrendGraphData {
  labels: string[];
  datasets: Dataset[];
}

// donut
interface DonutDataset {
  data: number;
  label: string;
}

interface DonutGraphData {
  donut_graph_data: DonutDataset[];
}

interface DonutDatasetOutput {
  data: number[];
  backgroundColor: string[];
}

interface DonutGraphOutput {
  labels: string[];
  datasets: DonutDatasetOutput[];
}
