// action-renderer.component.ts
import { HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AssignCaseDialogService } from 'src/app/services/assign-case-dialog/assign-case-dialog.service';
import { CaseInfoModalService } from 'src/app/services/case-info-modal/case-info-modal.service';
import { ConfirmDialogService } from 'src/app/services/confirmation-dialog/confirm-dialog.service';
import { HttpService } from 'src/app/services/http/http.service';
import { UtilsService } from 'src/app/services/utils/utilis.service';

@Component({
  selector: 'app-action-renderer',
  template: `
    <div
      *ngIf="isUnassigned(); else elseBlock"
      class="d-flex flex-row gap-1 align-items-center justify-content-start"
    >
      <div class="ss-grid-actions" (click)="onCaseAssign()">
        <i class="fas fa-paperclip"></i> Assign WSHA
      </div>
    </div>
    <ng-template #elseBlock>
      <div
        class="d-flex flex-row gap-1 align-items-center justify-content-start"
      >
        <div class="ss-grid-actions" (click)="showDetails()">
          <i class="fas fa-eye"></i> Show Details
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .ss-grid-actions {
        color: #000a89;
        font-size: 12px;
        cursor: pointer;
      }
      .ss-grid-actions:hover {
        color: #1b28ca;
        transition: 0.9s;
      }
    `,
  ],
})
export class ManagePublicCaseGridActionComponent
  implements ICellRendererAngularComp, OnDestroy
{
  rowData: any;
  refreshGrid: any;
  wshaList: any = [];
  caseDetails: any;
  allDetails: any;
  private assignCaseSubscription: any;

  /* inputDataModel = {
        caseDetails: undefined, wshaDetails: undefined
    }; */
  constructor(
    private assignCaseDialogService: AssignCaseDialogService,
    private caseDetailsModalService: CaseInfoModalService,
    private http: HttpService,
    private utils: UtilsService
  ) {}

  ngOnDestroy(): void {
    // Ensure to unsubscribe from the subscription to avoid memory leaks
    if (this.assignCaseSubscription) {
      this.assignCaseSubscription.unsubscribe();
      this.assignCaseDialogService.setSelectedWSHA(null);
    }
  }

  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
    this.refreshGrid = params.colDef?.cellRendererParams.refreshPublicCasesGrid;

    /* if (this.isUnassigned()) {
          // Only fetch WSHA list if the case is unassigned
          this.http.get('getWSHAAmbassadors').subscribe((res) => {
            this.wshaList = res['data'][0]['WSHA_AMBASSADORS'];
          });
        } */
    // get-case-details-by-id
    //let formData = new FormData();
    //   formData.append('case_id', this.rowData['case_id']);
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return true;
  }

  isUnassigned(): boolean {
    return this.rowData?.status === 'unassigned';
  }

  onCaseAssign() {
    //let designation = this.utils.getMappedRoleDisplayName(this.rowData.designation);
    if (this.isUnassigned()) {
      /* const wshaListData: any = localStorage.getItem('WSHAList');
      this.wshaList = JSON.parse(wshaListData); */
      // Once WSHA ;loist available then open popup model
      this.http.get('getWSHAAmbassadors').subscribe((res) => {
        this.wshaList = res['data'][0]['WSHA_AMBASSADORS'];

        this.assignCaseSubscription = this.assignCaseDialogService
          .open(this.wshaList, this.rowData)
          .subscribe((selectedItem) => {
            console.log('Selected Item >>>> ', selectedItem);
            let reqObj = {
              case_id: this.rowData.case_id,
              assigned_to: selectedItem.ambassador_id,
              assigned_by: this.utils.getLoggedInUserDetails()['user_id'],
            };
            this.http.post('assignCase', reqObj).subscribe(
              (res) => {
                this.refreshGrid();
                this.assignCaseSubscription.unsubscribe();
                this.assignCaseDialogService.setSelectedWSHA(null);
                // Do Notifiy user
                this.doNotify(this.rowData.case_id, selectedItem.ambassador_id, 'assign');
              },
              (err) => {
                console.log('Error In assign case for WSHA >>>>');
              }
            );
          });
      });
    }
  }

  showDetails() {
    if (this.rowData['status'] !== 'unassigned') {
      this.http
        .post('get-case-details-by-id-pdf', { case_id: this.rowData['case_id'] })
        .subscribe((res) => {
          this.caseDetails = res['data'];
          // once case details are available then open model dialog
          this.caseDetailsModalService
            .open(this.caseDetails)
            .subscribe((selectedItem) => {
              // do something once dialog opened
            });
        });
    }
  }
// send notification upon case assign
doNotify(caseId: any, assignedTo: any, action:any){
    let notificationObject = {
      user_id: assignedTo, 
      case_id: caseId,
      action: action, 
      data: {}, 
      message_title: null, 
      message_body: null
  };
   
  // API call /notify
  this.http.post('notify', notificationObject).subscribe(
    (res) => {
       console.log("Notification has been sent Successfully!");
    },
    (err) => {
      console.log('Error In send notification to user!!');
    }
  );

}

}
