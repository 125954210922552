<div class="modal-header">
  <h4 class="modal-title">Case Details</h4>
  <div class="ss-form-close-icon d-flex flex-row" style="gap: 15px">
    <div
    (click)="generatePDF(selectedItem, companyLogo, companyName )"
      class="ss-form-close-icon"
      style="cursor: pointer"
    >
      <i class="fas fa-download"></i>
    </div>
    <div class="ss-form-close-icon" (click)="onCancelClick()">
      <img src="assets/images/ss-icons/form-cross.svg" alt="" />
    </div>
  </div>
  <!-- <div class="ss-form-close-icon" (click)="onCancelClick()">
        <img src="assets/images/ss-icons/form-cross.svg" alt="">
    </div> -->
</div>
<div class="modal-body">
  <div class="container-fluid" *ngIf="selectedItem == undefined">
    <div class="row">
      There is something wrong, hence unable to fetch Case Details at present.
      Kindly contact Support Team.
    </div>
  </div>
  <div class="container-fluid" id="content" *ngIf="selectedItem">
    <div class="row" *ngIf="selectedItem[0].case_type == 'company'">
      <div class="form-secondary-text">Worksite Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Project Code</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].project_code }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Project Name</div>
          <div class="form-secondary-text">
            {{ utils.capitalizeName(selectedItem[0].project_name) }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Site ID</div>
          <div class="form-secondary-text">{{ selectedItem[0].siteid }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Contact</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].contact_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-secondary-text">Case Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case ID</div>
          <div class="form-secondary-text">{{ selectedItem[0].case_id }}</div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Status</div>
          <div class="form-secondary-text">
            {{ utils.capitalizeName(selectedItem[0].status) }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Category</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_category }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Location</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_location }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="d-flex flex-row justify-item-start align-items-center">
        <div
          *ngIf="selectedItem[0].case_type !== 'public'"
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Deadline</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_deadline }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Created At</div>
          <div class="form-secondary-text">
            {{ utils.getSGTZoneDateTime(selectedItem[0].created_at) }}
          </div>
        </div>
        <div
          class="col-6 info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Description</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].case_description }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="images.length > 0; else noMedia">
      <ng-image-slider
        [images]="images"
        [imageSize]="imageSize"
        [manageImageRatio]="true"
        [showArrow]="false"
        [infinite]="true"
        [animationSpeed]="2"
        [autoSlide]="false"
        #nav
      ></ng-image-slider>
      <div class="download-options">
        <button class="btn btn-primary" (click)="downloadImage(images)">
          <i class="fas fa-download"></i> Download
        </button>
      </div>
    </div>
    <ng-template #noMedia>
      <div>Case media is unavailable</div>
    </ng-template>

    <div class="row mt-3">
      <div class="form-secondary-text">Case Creator Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Creator ID</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_by }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Creator Name</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_by_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Creator Role</div>
          <div class="form-secondary-text">
            {{
              utils.getMappedRoleDisplayName(selectedItem[0].assigned_by_role)
            }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Creator Email</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_by_email }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="form-secondary-text">Case Closer Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Closer ID</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_to }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Closer Name</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_to_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Closer Role</div>
          <div class="form-secondary-text">
            {{
              utils.getMappedRoleDisplayName(selectedItem[0].assigned_to_role)
            }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Closer Email</div>
          <div class="form-secondary-text">
            {{ selectedItem[0].assigned_to_email }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="selectedItem[0].status === 'resolved'">
      <div class="form-secondary-text">Case approval Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case approval ID</div>
          <div class="form-secondary-text">
            {{ approval_id }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case approval Name</div>
          <div class="form-secondary-text">
            {{ approval_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case approval Role</div>
          <div class="form-secondary-text">
            {{utils.getMappedRoleDisplayName(approval_role)}}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case approval Email</div>
          <div class="form-secondary-text">
            {{ approval_email }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="selectedItem[0].status === 'reassign'">
      <div class="form-secondary-text">Case Reassignee Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Reassignee ID</div>
          <div class="form-secondary-text">
            {{ reassignee_id }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Reassignee Name</div>
          <div class="form-secondary-text">
            {{ reassignee_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Reassignee Role</div>
          <div class="form-secondary-text">
            {{utils.getMappedRoleDisplayName(reassignee_role)}}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Reassignee Email</div>
          <div class="form-secondary-text">
            {{ reassignee_email }}
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="selectedItem[0].status === 'reject'">
      <div class="form-secondary-text">Case Rejector Details</div>
      <div class="content-stat-devider mt-2"></div>
      <div class="d-flex flex-row justify-item-start align-items-center mt-3">
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Rejector ID</div>
          <div class="form-secondary-text">
            {{ rejector_id }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Rejector Name</div>
          <div class="form-secondary-text">
            {{ rejector_name }}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case Rejector Role</div>
          <div class="form-secondary-text">
            {{utils.getMappedRoleDisplayName(rejector_role)}}
          </div>
        </div>
        <div
          class="col info-item d-flex flex-column justify-item-start align-items-start gap-1"
        >
          <div class="form-section-title">Case approval Email</div>
          <div class="form-secondary-text">
            {{ rejector_email }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mt-5">
        <p-timeline
  [value]="selectedItem"
  align="alternate" 
  styleClass="customized-timeline responsive-timeline"
>
  <!-- Marker Template -->
  <ng-template pTemplate="marker" let-event>
    <span class="custom-marker p-shadow-2">
      <i
        class="fas"
        [ngClass]="{
          'fa-exclamation-circle unassigned': event.t_status === 'unassigned',
          'fa-lock-open open': event.t_status === 'open',
          'fa-hourglass-half inprogress': event.t_status === 'inprogress',
          'fa-check-circle resolved': event.t_status === 'resolved',
          'fa-times-circle rejected': event.t_status === 'reject',
          'fa-hourglass approval-pending-icon': event.t_status === 'approval pending',
          'fa-sync reassign-icon': event.t_status === 'reassign'
        }"
      ></i>
    </span>
  </ng-template>

  <!-- Content Template -->
  <ng-template pTemplate="content" let-event>
    <p-card
      [header]="utils.capitalizeName(event.t_status)"
      [subheader]="utils.getSGTZoneDateTime(event.t_updated_at)"
    >
      <div class="text-left">
        <div class="data-label">Reason</div>
        <div
          *ngIf="event.t_status !== 'reject'"
          class="form-secondary-text"
        >
          {{ event.t_status_reason ? event.t_status_reason : 'NA' }}
        </div>
        <div
          *ngIf="event.t_status === 'reject'"
          class="form-secondary-text"
        >
          {{ event.t_status_reason ? event.t_status_reason : 'NA' }}
        </div>
      </div>

      <!-- Case Media Section -->
      <div
        class="row mt-3"
        *ngIf="
          resolvedCaseMedia !== null &&
          resolvedCaseMedia.length > 0 &&
          event.t_status === 'approval pending'
        "
      >
        <ng-image-slider
          [images]="resolvedCaseMedia"
          [imageSize]="imageSize"
          [manageImageRatio]="true"
          [showArrow]="false"
          [infinite]="true"
          [animationSpeed]="2"
          [autoSlide]="false"
          #navResolved
        ></ng-image-slider>

        <div class="download-options">
          <button
            class="btn btn-primary"
            (click)="downloadImage(resolvedCaseMedia)"
          >
            <i class="fas fa-download"></i> Download
          </button>
        </div>
      </div>
    </p-card>
  </ng-template>
</p-timeline>

      </div>
    </div>
    
    
  </div>
</div>
<!-- Show these buttons only if the case status is approval pending -->
<div class="modal-footer">
  <button
    type="button"
    class="btn-custom"
    *ngIf="selectedItem[0].status === 'approval pending'"
    (click)="onApproveClick()"
  >
    Approve
  </button>
  <button
    type="button"
    *ngIf="selectedItem[0].status === 'approval pending'"
    class="btn-warning"
    (click)="onReassignClick()"
  >
    Reassign
  </button>
  <!-- Reject button -->
  <button
    type="button"
    *ngIf="selectedItem[0].status === 'approval pending'"
    class="btn-reject"
    (click)="onRejectClick()"
  >
    Reject
  </button>

  <!-- Show text input and submit button when the reassign button is clicked -->
  <div *ngIf="isReassingClicked" class="modal-backdrop">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Submit Reassign Reason</h4>
        <div class="close-icon" (click)="closeReassingModal()">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="modal-body">
        <input
          type="text"
          [(ngModel)]="statusReasonInput"
          placeholder="Enter Reassign reason"
          class="form-control"
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-submit-reassign"
          (click)="onSubmitReassign()"
        >
          Submit Reassign
        </button>
      </div>
    </div>
  </div>

  <!-- Show text input and submit button when the reject button is clicked -->
  <div *ngIf="isRejectClicked" class="modal-backdrop">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Submit Rejection Reason</h4>
        <div class="close-icon" (click)="closeRejectModal()">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="modal-body">
        <input
          type="text"
          [(ngModel)]="statusReasonInput"
          placeholder="Enter reject reason"
          class="form-control"
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn-submit-reject"
          (click)="onSubmitReject()"
        >
          Submit reject
        </button>
      </div>
    </div>
  </div>
  <button type="button" class="btn-closee" (click)="onCancelClick()">
    Close
  </button>
</div>
