import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subject, filter, map, merge, mergeMap, of } from 'rxjs';
import { UtilsService } from '../../utils/utilis.service';
import { Filters } from 'src/app/components/dto/filter.dto';
import { WaitingLoaderService } from '../../waiting-loader/waiting-loader.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardStatisticsService {
  sidebarData: any;
  // client dashboard data Observable
  clientDashboardData: BehaviorSubject<any> = new BehaviorSubject({});
  clientDashboardData$ = this.clientDashboardData.asObservable();
  // super admin dashboard data Observable
  superAdminDashboardData: BehaviorSubject<any> = new BehaviorSubject({});
  superAdminDashboardData$ = this.superAdminDashboardData.asObservable();
  // filters data
  filtersData: any;
  filtersDataSubject = new Subject<any>();
  filtersData$ = this.filtersDataSubject.asObservable();

  constructor(
    private http: HttpService,
    private socket: Socket,
    private utils: UtilsService,
    private waitingLoader: WaitingLoaderService
  ) {
    this.socket.connect();
  }

  getDashboardStatistics(dashboardType: any){
    let payload = {dashboard_type: dashboardType};
     return this.http.post('get-dashboard-statistics', payload);
  }

  getSuperAdminDashboardStatistics(){
    this.socket.emit('super-admin-dashboard-payload', {dashboard_type: 'public'});
        this.socket.fromEvent('super-admin-dashboard-data').subscribe((res: any)=>{
          // console.log("Socket data receved >>>> ", res);
          this.superAdminDashboardData.next(res);
        })
    this.filtersData$.subscribe((data)=>{
       this.filtersData = {dashboard_type: 'public', selectedDate: null, startDate: data.startDate || null, endDate: data.endDate || null};
       console.log("Filters data in service Payload >>>> ",  this.filtersData);
       //let filtersData =  
        this.socket.emit('super-admin-dashboard-payload', this.filtersData);
        this.socket.fromEvent('super-admin-dashboard-data').subscribe((res: any)=>{
          // console.log("Socket data receved >>>> ", res);
          this.superAdminDashboardData.next(res);
        })
    })
    
  }

  

  getClientDashboardStatistics(payload: any){
     this.socket.emit('client-dashboard-payload', payload);
     this.socket.fromEvent('client-dashboard-data').subscribe((res: any)=>{
       // console.log("Socket data receved >>>> ", res);
        this.clientDashboardData.next(res);
     })

     this.filtersData$.subscribe((data)=>{
      payload['siteid'] = data.siteid || null;
      payload['selectedDate'] = null;
      payload['startDate'] = data.startDate || null;
      payload['endDate'] = data.endDate || null;
      // this.filtersData = {...payload, selectedDate: null, startDate: data.startDate || null, endDate: data.endDate || null};
     // console.log("Filters data in service Payload >>>> ",  payload);
      this.socket.emit('client-dashboard-payload', payload);
      this.socket.fromEvent('client-dashboard-data').subscribe((res: any)=>{
       //console.log("Socket data receved >>>> ", res);
        this.clientDashboardData.next(res);
     })
   })


  }
}
