import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  // GET method without using generics
  get(endpoint: string): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.get(url);
  }

  // Post method without using generics
  post(endpoint: string, data: any, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post(url, data, { headers });
  }

  // POST method with FormData without using generics
  postWithFormData(endpoint: string, formData: FormData, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post(url, formData, { headers });
  }

  // PUT method without using generics
  put(endpoint: string, data: any, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.put(url, data, { headers });
  }

  // DELETE method without using generics
  delete(endpoint: string, headers?: HttpHeaders): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.delete(url, { headers });
  }
}
