// http-interceptor-loader.service.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { WaitingLoaderService } from '../waiting-loader/waiting-loader.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  // List of excluded API URLs
  private excludedUrls: string[] = [
    '/v1/api/getmasterdata',
    '/v1/api/getCompanyEmployees',
    '/v1/api/getSubContractors',
    '/v1/api/getCompanyContractorAdmin',
    '/v1/api/getWSHAAmbassadors',
    '/v1/api/getCases',
    '/v1/api/getCaseStatistics',
    '/v1/api/get-case-details-by-id',
    '/v1/api/get-case-details-by-id-pdf',
    '/v1/api/login',
    '/v1/api/get-dashboard-statistics',
    '/v1/api/getBraodcastTargetGroupIds',
    '/v1/api/getWebBoradcastHistory',
    '/v1/api/getVacancies',
    '/v1/api/get-hsef-registries',
    '/v1/api/get-hsef-registry-details',
    '/v1/api/getClientSettings',
    '/v1/api/get-tbm',
    '/v1/api/register-device-token',
    '/v1/api/manage-work-sites',
    '/v1/api/get-vendor-employees',
    '/v1/api/case-actions',
    '/v1/api/updatestatus',
    '/v1/api/notify',
  ];

  constructor(
    private toastr: ToastrService,
    private waitingLoader: WaitingLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.waitingLoader.show();

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleHttpError(error);
        return throwError(error);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 200) {
          const requestUrl = event.url || ''; // Get the URL from the event

          // Check if the request URL is not in the excluded list
          if (!this.isExcludedUrl(requestUrl)) {
            this.handleHttpSuccess(event);
          }
        }
        return event;
      }),
      finalize(() => {
        // Hide the loader after the request is completed (success or error)
        this.waitingLoader.hide();
      })
    );
  }

  private handleHttpError(error: HttpErrorResponse): void {
    let errorMessage = 'An error occurred';

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.error && error.error.message) {
      errorMessage = error.error.message;
    }

    this.toastr.error(errorMessage, 'Error', {
      closeButton: true,
      timeOut: 3000,
    });
  }

  private handleHttpSuccess(event: HttpResponse<any>): void {
    // Check if the response body has a 'message' property
    // console.log("HTTP Interceptor EVENT BODY >>>> ", event.body);
    if (event.body && event.body.message) {
      this.toastr.success(event.body.message, 'Success', {
        closeButton: true,
        timeOut: 3000,
      });
    } else {
      // Default success message if 'message' property is not present
      this.toastr.success('Request successful', 'Success', {
        closeButton: true,
        timeOut: 3000,
      });
    }
  }

  private isExcludedUrl(url: string): boolean {
    // Check if the URL is in the excluded list
    return this.excludedUrls.some((excludedUrl) => url.includes(excludedUrl));
  }
}
